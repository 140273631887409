<template>
  <div>
    <b-card bg-variant="light" header="Vertimientos" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-vertim_alcantarillado"
                        label="Tiene vertimiento al alcantarillado"
                        label-for="vertim_alcantarillado"
                    >
                        <b-form-select 
                            id="vertim_alcantarillado" 
                            v-model="form.vertim_alcantarillado" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-vert_efluentes"
                        label="Lugar de descarga de efluentes"
                        label-for="vert_efluentes"
                    >
                        <b-form-input
                            id="vert_efluentes"
                            v-model="form.vert_efluentes"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-cert_disposicion"
                        label="Tiene Certificado de Disposición de un Tercero Autorizado "
                        label-for="cert_disposicion"
                    >
                        <b-form-select 
                            id="cert_disposicion" 
                            v-model="form.cert_disposicion" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-if="form.cert_disposicion==1" >
                    <b-form-group
                        id="group-num_cert_disposicion"
                        label="Núm. certificado de disposición"
                        label-for="num_cert_disposicion"
                    >
                        <b-form-input
                            id="num_cert_disposicion"
                            v-model="form.num_cert_disposicion"
                            :disabled="verDetalles?true:false"
                            :required="form.cert_disposicion==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.cert_disposicion==1">
                    <b-form-group
                        id="group-archivo_disposicion"
                        label="Archivo de la licencia dispo."
                        label-for="archivo_disposicion"
                       
                    >
                        <b-form-file
                            id="archivo_disposicion"
                            :state="Boolean(form.archivo_disposicion)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenDisposicion" 
                            v-if=" !cargadoDisposicion"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoDisposicion" @click="descargarPDF(form.archivo_disposicion, 'Disposicion')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-id_proveedor_disp"
                        label="Identificador del proveedor de disposicion residuos"
                        label-for="id_proveedor_disp"
                    >
                        <b-form-select 
                            id="id_proveedor_disp" 
                            v-model="form.id_proveedor_disp" 
                            class="mb-3" 
                            required 
                            :options="mapearProveedores"
                            :disabled="verDetalles?true:false"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-req_permiso_vertim"
                        label="Requirer permiso de vertimiento"
                        label-for="req_permiso_vertim"
                    >
                        <b-form-select 
                            id="req_permiso_vertim" 
                            v-model="form.req_permiso_vertim" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-if="form.req_permiso_vertim==1" >
                    <b-form-group
                        id="group-num_resolucion_vertim"
                        label="Núm. resolución de vertimiento"
                        label-for="num_resolucion_vertim"
                    >
                        <b-form-input
                            id="num_resolucion_vertim"
                            v-model="form.num_resolucion_vertim"
                            :disabled="verDetalles?true:false"
                            :required="form.req_permiso_vertim==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.req_permiso_vertim==1">
                    <b-form-group
                        id="group-archivo_vertimiento"
                        label="Archivo de la lic./resol. vertimiento"
                        label-for="archivo_vertimiento"
                       
                    >
                        <b-form-file
                            id="archivo_vertimiento"
                            :state="Boolean(form.archivo_vertimiento)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenVertimiento" 
                            v-if=" !cargadoVertimiento"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoVertimiento" @click="descargarPDF(form.archivo_vertimiento, 'Vertimiento')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-caracterizacion_aguas"
                        label="Presenta caracterizacion de aguas"
                        label-for="caracterizacion_aguas"
                    >
                        <b-form-select 
                            id="caracterizacion_aguas" 
                            v-model="form.caracterizacion_aguas" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-tiene_PTAR"
                        label="Tiene PTAR"
                        label-for="tiene_PTAR"
                    >
                        <b-form-select 
                            id="tiene_PTAR" 
                            v-model="form.tiene_PTAR" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.tiene_PTAR==1">
                    <b-form-group
                        id="group-tipo_PTAR"
                        label="Tipo PTAR"
                        label-for="tipo_PTAR"
                    >
                        <b-form-select 
                            id="tipo_PTAR" 
                            v-model="form.tipo_PTAR" 
                            class="mb-3" 
                            :required ="form.tiene_PTAR==1"
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">PTAP</b-form-select-option>
                            <b-form-select-option value="1">PTARD</b-form-select-option>
                            <b-form-select-option value="1">PTARND</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'VertimientoVigilanciaControl',
    data() {
      return {
        editar:false,
        cargadoDisposicion: false,
        cargadoVertimiento: false,
        form: {
            id: null,
            vertim_alcantarillado: null,
            vert_efluentes: null,
            cert_disposicion: null,
            num_cert_disposicion: null,
            archivo_disposicion: null,
            id_proveedor_disp: null,
            req_permiso_vertim: null,
            num_resolucion_vertim: null,
            archivo_vertimiento: null,
            caracterizacion_aguas:null,
            tiene_PTAR: null,
            tipo_PTAR:null
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarVertimiento(this.visitaEnGestion.id)
        }
        try{
            await this.cargarProveedores()
        }catch(e){
            encontrarError(this, e.data)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultVertimiento', 'vertimientoCargado' ]),
        ...mapState('consultaEmpresas', ['proveedoresServicio' ]),

        mapearProveedores(){
            let mapeadas = this.proveedoresServicio.map(item=>{
                let proveedor={
                    value: item.id_prov,
                    text: item.nombre
                }
                return proveedor
            })
            return mapeadas
        },
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearVertimiento', 'cargarVertimientoById', 'editarVertimiento']),
        ...mapActions('consultaEmpresas', ['cargarProveedores']),

        async cargarVertimiento(id){
            this.activarLoading(true)
            try{
                await this.cargarVertimientoById(id)
                console.log('esto es lo cargado', this.vertimientoCargado[0])
                if(this.vertimientoCargado.length>0){
                    this.editar=true
                    this.form=this.vertimientoCargado[0]
                    this.form.tiene_PTAR=this.vertimientoCargado[0].tiene_ptar
                    this.form.tipo_PTAR=this.vertimientoCargado[0].tipo_ptar
                    this.cargadoVertimiento =  this.form.archivo_vertimiento?true:false
                    this.cargadoDisposicion =  this.form.archivo_disposicion?true:false
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        imagenVertimiento(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'vertimiento')
        },
        
        imagenDisposicion(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'disposicion')
        },

        crearBase64(file, lugar){
            if(file){
                let reader = new FileReader();
                reader.onload=(e)=>{
                    let archivo = e.target.result
                    let base = archivo.split('base64,')
                    let pdf = base[1]

                    if(lugar=='vertimiento'){
                        this.form.archivo_vertimiento = pdf;
                    }
                    if(lugar=='disposicion'){
                        this.form.archivo_disposicion = pdf;
                    }
                }
                reader.readAsDataURL(file)
            }else{
                    if(lugar=='vertimiento'){
                        this.form.archivo_vertimiento = null;
                    }
                    if(lugar=='disposicion'){
                        this.form.archivo_disposicion = null;
                    }
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Vertimientos, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                vertimiento: this.form
            }

             try{
                 if(this.editar){
                     await this.editarVertimiento(dataGuardar)
                 }else{
                     await this.crearVertimiento(dataGuardar)
                 }
                if(this.resultVertimiento.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarVertimiento(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
        

        descargarPDF(pdf, nombre) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `licencia_${nombre}.pdf`;
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
    }
  }
</script>

<style scoped>
    
</style>